<template>
  <div class="outer">
    <v-sheet
      v-for="tripWagon in convoyWagonsByExecutionVoyage"
      :key="tripWagon.id"
    >
      <TripWagonExecutionViewPanel
        :tripWagon="tripWagon"
        :convoyWagon="tripWagon.convoyWagon"
      />
    </v-sheet>
  </div>
</template>

<script>
import TripWagonExecutionViewPanel from "../../components/misc/TripWagonExecutionViewPanel";

export default {
  name: "TripWagonsExecutionViewPanel",
  components: {
    TripWagonExecutionViewPanel,
  },
  mixins: [],
  data() {
    return {};
    search=null;
  },
  props: {
    tripWagons: {
      type: Array,
      default: () => [],
    },
    convoys: {
      type: Array,
      default: () => [],
      required: false,
    },
  },

  computed: {
    convoyWagonsByExecutionVoyage() {
      var cws = [];
      this.tripWagons.forEach((tripWagon) => {
        tripWagon.convoyWagon = undefined;
        this.convoys.forEach((convoglio) => {
          if (convoglio.listCarri && convoglio.listCarri.length > 0) {
            convoglio.listCarri.forEach((carroConvoglio) => {
              if (tripWagon.visitaCarroId === carroConvoglio.visitaCarro.id) {
                tripWagon.convoyWagon = carroConvoglio;
              }
            });
          }
        });
        cws.push(tripWagon);
      });
     return this._.orderBy(cws, [cv => cv.posizione]); 
    }
  },
};
</script>

<style scoped>
.outer {
  width: 100%;
  height: 100%;
  white-space: nowrap;
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}

.outer div {
  float: none;
  height: 100%;
  margin: 0 1%;
  display: inline-block;
}
</style>