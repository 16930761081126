<template>
  <div class="py-3">
    <v-tabs color="warning" v-model="tabs">
      <v-tab>Carri</v-tab>
      <v-tab>Uti</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tabs" touchless>
      <v-tab-item>
        <div class="py-3">
          <v-text-field
                  v-model="wagonsSearch"
                  append-icon="mdi-magnify"
                  label="Cerca per Tipo Carro / Targa"
                  single-line
                  hide-details
                  style="max-width:300px;"
                />
        </div>
        <v-data-table 
          :items="wagonsList" 
          :headers="wagonsHeaders"
          :items-per-page="10"
          :search="wagonsSearch"
          :custom-filter="wagonsCustomFilter"
          :footer-props="{
            itemsPerPageText: 'Risultati per pagina',
            itemsPerPageAllText: 'Tutti',
            showFirstLastPage: true,
          }"/>
          
      </v-tab-item>
      <v-tab-item>
        <div class="py-3">
          <v-text-field
                  v-model="utiSearch"
                  append-icon="mdi-magnify"
                  label="Cerca per Uti / Targa Carro"
                  single-line
                  hide-details
                  style="max-width:300px;"
                />
        </div>
        <v-data-table 
          :items="utiList" 
          :headers="utiHeaders"
          :items-per-page="10"
          :search="utiSearch"
          :custom-filter="utiCustomFilter"
          :footer-props="{
            itemsPerPageText: 'Risultati per pagina',
            itemsPerPageAllText: 'Tutti',
            showFirstLastPage: true,
          }"/>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import TripVisualizationMixins from '../mixins/tripVisualizationMixins';

export default {
  name: "TripUtiWagonsListsPanel",
  components: {
    
  },
  mixins: [TripVisualizationMixins],
  data() {
    return {
      tabs: null,
      wagonsSearch: null,
      wagonsHeaders: [
          { text: 'Tipo Carro', value: 'tipo', class: 'header text-uppercase' },
          { text: 'Targa', value: 'targa', class: 'header text-uppercase' },     
          { text: 'Stato', value: 'stato', class: 'header text-uppercase' },  
          { text: 'Parco binari', value: 'parcoBinari', class: 'header text-uppercase' },
          { text: 'Binario', value: 'binario', class: 'header text-uppercase' },
          { text: 'Convoglio', value: 'convoglio', class: 'header text-uppercase' }               
      ],
      utiSearch: null,
      utiHeaders: [
          { text: 'Tipo Uti', value: 'tipoUti', class: 'header text-uppercase' },     
          { text: 'Uti', value: 'utiNumber', class: 'header text-uppercase' },  
          { text: 'Descrizione merce', value: 'descrizioneMerce', class: 'header text-uppercase' },
          { text: 'Peso Lordo', value: 'pesoLordo', class: 'header text-uppercase' },
          { text: 'Lunghezza', value: 'lunghezza', class: 'header text-uppercase' },
          { text: 'F/E', value: 'fullEmpty', class: 'header text-uppercase' },
          { text: 'Pericoloso', value: 'pericoloso', class: 'header text-uppercase' },
          { text: 'Area di Stoccaggio', value: 'areaDiStoccaggio', class: 'header text-uppercase' },
          { text: 'Tipo Carro', value: 'tipoCarro', class: 'header text-uppercase' },
          { text: 'Targa Carro', value: 'targaCarro', class: 'header text-uppercase' },
          { text: 'Parco binari', value: 'parcoBinari', class: 'header text-uppercase' },
          { text: 'Binario', value: 'binario', class: 'header text-uppercase' },
          { text: 'Stato', value: 'statoUti', class: 'header text-uppercase' }
      ]
    };
  },
  computed: {
    wagonsList() {
      // console.log("tripWagons", this.tripWagons);
      var wl = [];
      this.tripWagons.forEach(wagon => { 
        wl.push(this.toWagonListElement(wagon));    
      });
      return wl;
    },
    utiList() {
      var ul = [];
      this.tripWagons.forEach(wagon => {        
        wagon.listUti.forEach(utiCarro => {
          ul.push(this.toUtiListElement(wagon, utiCarro));
        });        
      });
      return ul;
    }
  },
  props: {
    tripWagons: {
      type: Array,
      default: () => [],
    }
  },
  methods: {
    toWagonListElement(wagon) {
      var w = {};
      w.tipo = this.getCarroType(wagon);
      w.targa = this.getCarroLicensePlate(wagon);
      if (w.tipo===w.targa) {
        w.targa = '';
      }
      w.stato = this.getCarroWorkingState(wagon);
      var convoglio = this.getConvoglio(wagon);
      if (convoglio) {
        w.convoglio = convoglio.nome;
      }
      var binario = this.getBinario(wagon);
      if (binario) {
        w.binario = binario.numero;
      }
      var parcoBinari = this.getParcoBinari(wagon);
      if (parcoBinari) {
        w.parcoBinari = codiceParcoBinari;
      }    
      return w;
    },
    toUtiListElement(wagon, utiCarro) {
      var u = {};
      u.riserva = false; // TODO calcolare la riserva come uti con booking per il viaggio ma senza uticarro
      u.tipoUti = this.getUtiType(utiCarro.visitaUti);
      u.utiNumber = this.getUtiCode(utiCarro.visitaUti);
      u.descrizioneMerce = utiCarro.visitaUti.merce;
      u.pesoLordo = this.getUtiWeight(utiCarro.visitaUti);
      u.lunghezza = this.getUtiLength(utiCarro.visitaUti);
      u.fullEmpty = this.getFullEmpty(utiCarro.visitaUti);
      u.pericoloso = this.getUtiDangerousFlag(utiCarro.visitaUti)?'Sì':'No';
      u.areaDiStoccaggio = 'TODO'; // TODO utiCarro.areaScarico attualmente ma non e' stesso parametro
      var wle = this.toWagonListElement(wagon);
      u.tipoCarro = wle.tipo;
      u.targaCarro = wle.targa;
      u.parcoBinari = wle.parcoBinari;
      u.binario = wle.binario;
      u.statoUti = utiCarro.stato;
      return u;
    },
    wagonsCustomFilter (value, search, item) {
      return item && search && (item.tipo || item.targa)
        && (            
          item.tipo.toLowerCase().includes(search.toLowerCase())
          || item.targa.toLowerCase().includes(search.toLowerCase())
        );
    },
    utiCustomFilter (value, search, item) {
      return item && search && (item.utiNumber || item.targaCarro)
        && (                  
          item.utiNumber.toLowerCase().includes(search.toLowerCase())
          || item.targaCarro.toLowerCase().includes(search.toLowerCase())
        );
    },
  }
};
</script>

<style scoped>

</style>