<template>
  <v-card min-width="400">
    <v-app-bar flat color="warning">
      <v-toolbar-title class="text-h5 font-weight-black">{{ tripWagon.visitaCarro.targa }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-chip class="text-caption header ma-1"
        ><b>{{ this.labelConvenzionale }}</b></v-chip
      >
    </v-app-bar>

    <v-card-text class="grey lighten-2">
      <v-container class="pa-0 ma-0">
        <v-row align="center" justify="center" class="pa-0 ma-0">
          <v-col v-if="utiWagonDepartureList && utiWagonDepartureList.length > 0" class="pa-0 ma-0">
            <v-sheet
              elevation="1"
              rounded="lg"
              :class="`text-body-1 font-weight-regular pa-3 ma-3 ${getClass(utiCarro)}`"
              v-for="utiCarro in utiWagonDepartureList"
              :key="utiCarro.id"
            >
              <v-container class="pa-0 ma-0">
                <v-row class="pa-0 ma-0" justify="center">
                  <v-col cols="12" v-if="utiCarro.visitaUti && utiCarro.visitaUti.uti" class="font-weight-bold pa-0 ma-0">{{ utiCarro.visitaUti.uti.codice }}</v-col>
                  <v-col cols="12" v-else class="font-weight-bold pa-0 ma-0">{{ utiCarro.visitaUti.merce }}</v-col>
                </v-row>
              </v-container>
            </v-sheet>
          </v-col>

          <v-col class="pa-0 ma-0" v-else>
            <v-sheet elevation="1" rounded="lg" class="text-body-1 font-weight-regular pa-3 ma-3">
              NO UTI
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "TripWagonExecutionViewPanel",
  components: {},
  mixins: [],
  data() {
    return {};
  },
  props: {
    tripWagon: {
      type: Object,
      required: true,
    },

    convoyWagon: {
      type: Object,
      required: false,
    },
    executionVoyageWagon: {
      type: Object,
      required: false,
    },
  },
  computed: {
    isConvenzionale() {
      let tipoCarro = null;
      if (this.tripWagon.visitaCarro.tipoCarro) {
        tipoCarro = this.tripWagon.visitaCarro.tipoCarro;
      } else if (this.tripWagon.visitaCarro.carro && this.tripWagon.visitaCarro.carro.tipoCarro) {
        tipoCarro = this.tripWagon.visitaCarro.carro.tipoCarro;
      }
      return tipoCarro.convenzionale;
    },
    labelConvenzionale() {
      if (this.isConvenzionale) {
        return "CONV.";
      } else {
        return "NON CONV.";
      }
    },

    utiWagonDepartureList() {
      var wdl = [];
      if (this.tripWagon.listUti && this.tripWagon.listUti.length > 0) {
        wdl = this.tripWagon.listUti.filter(uti => uti.tipoMovimento === "IMPORT");
      }

      if (this.convoyWagon) {
        if (this.convoyWagon.listUti && this.convoyWagon.listUti.length > 0) {
          this.convoyWagon.listUti.forEach(utiWagon => {
            if (wdl && wdl.length > 0) {
              if (utiWagon.remainOnBoardScarico) {
                wdl.push(utiWagon);
              }
            }
          });
        }
      }
      return wdl;
    },
  },
  methods: {
    getClass(utiCarro) {
      if (utiCarro.remainOnBoardScarico && utiCarro.remainOnBoardScarico === true) {
        return "uti-rob";
      }
    },
  },
};
</script>

<style scoped>
.uti-rob {
  background-image: repeating-linear-gradient(-45deg, #ffffff, #ffffff 5px, rgb(196, 196, 192) 5px, rgb(196, 196, 192) 10px) !important;
}
</style>
