var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-3"},[_c('v-tabs',{attrs:{"color":"warning"},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tab',[_vm._v("Carri")]),_c('v-tab',[_vm._v("Uti")])],1),_c('v-tabs-items',{attrs:{"touchless":""},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tab-item',[_c('div',{staticClass:"py-3"},[_c('v-text-field',{staticStyle:{"max-width":"300px"},attrs:{"append-icon":"mdi-magnify","label":"Cerca per Tipo Carro / Targa","single-line":"","hide-details":""},model:{value:(_vm.wagonsSearch),callback:function ($$v) {_vm.wagonsSearch=$$v},expression:"wagonsSearch"}})],1),_c('v-data-table',{attrs:{"items":_vm.wagonsList,"headers":_vm.wagonsHeaders,"items-per-page":10,"search":_vm.wagonsSearch,"custom-filter":_vm.wagonsCustomFilter,"footer-props":{
          itemsPerPageText: 'Risultati per pagina',
          itemsPerPageAllText: 'Tutti',
          showFirstLastPage: true,
        }}})],1),_c('v-tab-item',[_c('div',{staticClass:"py-3"},[_c('v-text-field',{staticStyle:{"max-width":"300px"},attrs:{"append-icon":"mdi-magnify","label":"Cerca per Uti / Targa Carro","single-line":"","hide-details":""},model:{value:(_vm.utiSearch),callback:function ($$v) {_vm.utiSearch=$$v},expression:"utiSearch"}})],1),_c('v-data-table',{attrs:{"items":_vm.utiList,"headers":_vm.utiHeaders,"items-per-page":10,"search":_vm.utiSearch,"custom-filter":_vm.utiCustomFilter,"footer-props":{
          itemsPerPageText: 'Risultati per pagina',
          itemsPerPageAllText: 'Tutti',
          showFirstLastPage: true,
        }}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }