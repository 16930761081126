<template>
  <v-container fill-height fluid>
    
    <v-row>
      <v-col cols="12">        
        <v-row>
          <v-col cols="12">
            <v-expansion-panels v-model="infoPanel" multiple>
              <v-expansion-panel>
                <v-expansion-panel-header class="text-body-1 text-uppercase font-weight-regular py-2 panelheader">
                  INFO CONVOGLIO
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ConvoyInfoPanelFull :convoglio="this.convoglio" isExecution/>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>            
          </v-col>
        </v-row>
      </v-col>
    </v-row> 

    <v-row>
      <v-col cols="12">    
        <v-btn
            block
            color="primary"
            @click="goToLavorazioneConvoglioPartenza()"
            title="Lavorazione carico convoglio"
            x-large
            >
             Lavora Carico Convoglio
            </v-btn>   
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <div id="convoyVis" class="convoy-vis">
          <div id="convoyCntr" class="convoy-cntr">
            <ConvoySpatialVisualization :convoglio="this.convoglio" :lengthInterval="20" filterView="PARTENZA" />
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <ConvoyUtiList :convoy="this.convoglio" />
      </v-col>
    </v-row>

    <v-row v-if="editingEnabled">
      <v-col cols="12">
        <v-expansion-panels v-model="managementPanel" :popout="false" :inset="false" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-body-1 text-uppercase font-weight-regular py-2 panelheader">
              GESTIONE CONVOGLIO
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ConvoyActionsManagement :convoglio="this.convoglio" :listOccupazione="listOccupazioneBinari" :tabsRendered="[true, true, false]"/>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ConvoyInfoPanelFull from '../../misc/ConvoyInfoPanelFull';
import ConvoySpatialVisualization from './ConvoySpatialVisualization';
import ConvoyActionsManagement from './ConvoyActionsManagement';

// import calLegend from '../../misc/Legend';
import ConvoyUtiList from './ConvoyUtiList';
// import wagonOrdering from './WagonOrdering';

import convoyVisualizationMixins from '../mixins/convoyVisualizationMixins';

export default {
  name: "ConvoyDepartureVisualization",
  components: {
    ConvoyInfoPanelFull,
    ConvoySpatialVisualization,
    ConvoyActionsManagement, 
    ConvoyUtiList
    // calLegend,
    // convoyUtiList,
    // wagonOrdering,      
  },
  props: {
    convoyId: {
      type: Number,
      required: true
    },
    voyageId: {
      type: Number,
      required: false
    }
  },
  mixins: [convoyVisualizationMixins],
  data() {
    return {
      infoPanel: [0],
      // editPanel: [0],
      // legendPanel: [],
      // wagonsPanel: [1],
      managementPanel: [0],
      convoglio: {},
      listOccupazioneBinari: [],
    };
  },
  created(){
    this.$eventBus.$on('convoyReload', async () => {
      await this.$wsHubOnDataChangedEvent('railVisualizationRT');
      await this.fetchData();
    });
  },
  async mounted(){  
    this.initComponent();  
    await this.fetchData();     
  },
  computed: {
    editingEnabled() {
      return this.convoglio.stato 
        && this.convoglio.stato.toLowerCase() != 'uscitoterminal'
        && this.convoglio.stato.toLowerCase() != 'inlavorazione';
    },
  },
  watch: {
    convoyId: function (newId) {
        this.fetchData();          
    }
  },
  methods: {
    async fetchData() {
      try {
        this.convoglio = await this.$api.trainData.getConvoglioById(+this.convoyId);

        const occBinResp=await this.$api.get(this.$apiConfiguration.BASE_PATH+"occupazioneBinari/list", {params: {	filters: {"convoglio_id___EQ": parseInt(this.convoyId) }}});
        this.listOccupazioneBinari=occBinResp.data;
      } catch(e) {
        console.log(e);
      }
    },
    goToLavorazioneConvoglioPartenza() {
        var path="/callTreno/LavorazioneConvoglioPartenza/" + this.convoglio.id + "/";
        if (this.voyageId) {
            path+=this.voyageId;
        }
        this.$router.push({ path: path });
        return;     
    },
  }
};
</script>

<style scoped>
.convoy-vis{
  position: relative;
  overflow-x:scroll;
  cursor: pointer;
}
</style>
