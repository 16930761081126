<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="1">
        <v-btn color="warning" @click="$router.go(-1)">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="11" class="text-h5 font-weight-regular">
        Lavorazione viaggio in partenza da {{ viaggio.nodoPartenza | codice }} a
        {{ viaggio.nodoArrivo | codice }}
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-expansion-panels v-model="infoPanel" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header
              class="text-body-1 text-uppercase font-weight-regular py-2 panelheader"
            >
              Dati viaggio
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <TripInfoPanelFull :viaggio="viaggio" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-tabs color="primary" v-model="tabExecution">
          <v-tab>Viaggio</v-tab>
          <v-tab>Convogli</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tabExecution" touchless>
          <v-tab-item>
            <v-card>
              <v-card-title
                class="text-body-1 text-uppercase font-weight-regular py-2 my-2 panelheader"
              >
                CARRI VIAGGIO
              </v-card-title>
              <v-card-text>
                <TripWagonsExecutionViewPanel
                  :tripWagons="viaggio.listCarri"
                  :convoys="convogli"
                />
              </v-card-text>
            </v-card>

            <TripUtiWagonsListsPanel
              :tripWagons="viaggio.listCarri"
              :tripId="tripId"
            />
          </v-tab-item>
          <v-tab-item>
            <v-container fill-height fluid>
              <v-row>
                <v-col cols="12">
                  <v-tabs color="warning" v-model="tabConvoy">
                    <v-tab v-for="convoglio in convogli" :key="convoglio.id">{{
                      convoglio.nome
                    }}</v-tab>
                  </v-tabs>

                  <v-tabs-items v-model="tabConvoy" touchless>
                    <v-tab-item
                      v-for="convoglio in convogli"
                      :key="convoglio.id"
                    >  <ConvoyDepartureVisualization
                        :convoyId="convoglio.id"
                        :voyageId="+tripId"
                      />
                    </v-tab-item>
                  </v-tabs-items>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ToastMixin from "../../mixins/ToastMixin";
import TripVisualizationMixins from "../../components/rails/mixins/tripVisualizationMixins";

import TripInfoPanelFull from "../../components/misc/TripInfoPanelFull";
import ConvoyDepartureVisualization from "../../components/rails/convoy/ConvoyDepartureVisualization";
import TripWagonsExecutionViewPanel from "../../components/misc/TripWagonsExecutionViewPanel";
import TripUtiWagonsListsPanel from "../../components/rails/trip/TripUtiWagonsListsPanel";

export default {
  name: "LavorazioneViaggioPartenza",
  components: {
    ConvoyDepartureVisualization,
    TripInfoPanelFull,
    TripWagonsExecutionViewPanel,
    TripUtiWagonsListsPanel,
  },
  mixins: [ToastMixin, TripVisualizationMixins],
  filters: {
    codice: function (value) {
      if (value) return value.codice;
    },
  },
  data() {
    return {
      tabExecution: null,
      tabConvoy: null,
      viaggio: {},
      infoPanel: [0],
      editPanel: [0, 1, 2, 3],
      convogli: [],
    };
  },
  created() {
    this.$eventBus.$on("convoyReload", async () => {
      await this.fetchData();
    });
  },
  async mounted() {
    await this.fetchData();
  },
  computed: {},
  methods: {
    async fetchData() {
      try {
        this.viaggio = await this.$api.trainData.getViaggioById(+this.tripId);
        this.convogli = await this.$api.trainData.findAllConvogliByViaggioTrenoPartenza(
          +this.tripId
        );
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped>
</style>
